import React from 'react';
import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import classNames from 'classnames';
import { RatingStars } from './rating-stars/rating-stars';
import { OneStar } from './rating-stars/one-star';
import withTranslate from '../../hoc/with-translate';
import styles from './ratings-display.scss';

export const RatingsDisplayLayout = {
  default: 'default',
  bracket_separated: 'bracket-separated',
  with_range: 'with-range',
  count_only: 'count-only',
};

const RatingsDisplay = ({
  className,
  rating,
  count,
  isLoading = false,
  useTransitions = false,
  t,
  layout = RatingsDisplayLayout.default,
}) => {
  const withTransitions = React.useRef(useTransitions);

  const getRatingInfo = () => {
    switch (layout) {
      case RatingsDisplayLayout.default:
        return `${rating.toFixed(1)} | ${t('ratings-display.count', {
          count,
        })}`;
      case RatingsDisplayLayout.bracket_separated:
        return `${rating.toFixed(1)} (${count})`;
      case RatingsDisplayLayout.count_only:
        return `(${count})`;
      case RatingsDisplayLayout.with_range:
        return `${rating.toFixed(1)} / 5 (${count})`;
      default:
        return;
    }
  };

  return (
    <div
      className={classNames(
        styles.container,
        { [styles.withTransitions]: withTransitions.current },
        styles[layout],
        className,
      )}
      data-hook={classNames('ratings-display', !isLoading ? 'is-ready' : '')}
    >
      {layout === RatingsDisplayLayout.with_range ? (
        <OneStar
          a11yLabel={t('ratings-display.rating-aria-label', { rating })}
        />
      ) : (
        <RatingStars
          rating={isLoading ? 0 : rating}
          a11yLabel={t('ratings-display.rating-aria-label', { rating })}
        />
      )}
      {!isLoading && (
        <span className={styles.ratingInfo}>
          {count === 0 ? t('ratings-display.no-ratings') : getRatingInfo()}
        </span>
      )}
    </div>
  );
};

RatingsDisplay.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  rating: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  layout: PropTypes.bool,
};

export default flowRight(withTranslate)(RatingsDisplay);
